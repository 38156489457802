import { TypographyH2 } from '~/core/ui/Heading'
import { Trans, useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import { i18n } from 'next-i18next'
import { Button } from '~/core/ui/Button'
import Link from 'next/link'
import IconWrapper from '~/core/ui/IconWrapper'
import Slider, { Settings } from 'react-slick'
import { useRef, useState } from 'react'
import Image from 'next/image'

import { CONTACT_REQUEST_URL } from '~/core/constants/url'
import DotBadge from './components/DotBadge'

const settingsSliderTestimonial: Settings = {
  dots: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  infinite: true,
  accessibility: false,
  draggable: false
}

const settingsSliderAvatar = {
  dots: false,
  accessibility: false,
  draggable: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  infinite: true
}

const LEAR_MORE_LINKS = [
  CONTACT_REQUEST_URL,
  CONTACT_REQUEST_URL,
  CONTACT_REQUEST_URL,
  CONTACT_REQUEST_URL,
  CONTACT_REQUEST_URL
]

const InnovativeSection: FCC<{
  className?: string
  hasRequestForm?: boolean
}> = ({ className = '', hasRequestForm = false }) => {
  const { t } = useTranslation()
  const avatarSliderRef = useRef<Slider>(null)
  const testimonialSliderRef = useRef<Slider>(null)
  const [currentSliderIdx, setCurrentSliderIdx] = useState(1)

  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    })
  }

  return (
    <div
      className={`overflow-hidden mt-[100px] desktop:mt-[164px] ${className}`}>
      <div className="flex flex-col gap-3 justify-center max-w-3xl mx-auto items-center text-center">
        <DotBadge label={t('lp:innovative_section:tag')} />
        <TypographyH2 className="text-center text-4xl tablet:text-4.5xl font-normal">
          <Trans i18nKey="lp:innovative_section:title">
            <br />
            <span className="text-4xl tablet:text-4.5xl font-normal text-primary-400" />
          </Trans>
        </TypographyH2>
      </div>

      <div className="mt-[52px] bg-blue-50 flex flex-col items-center justify-center">
        <div className="relative">
          <div className="absolute h-[714px] w-[20px] border-l -left-16 hidden tablet:block">
            <Image
              src={`/img/feature/lp/circle.svg`}
              alt={`circle_line`}
              width={10}
              height={9}
              className="-ml-[6px] mt-16"
            />
          </div>
        </div>
        <div className="w-full border-b border-[#E3E6EC] flex justify-center">
          <div className="pl-[40px] max-w-[1216px] border-l border-[#E3E6EC] py-5">
            <Slider ref={avatarSliderRef} {...settingsSliderAvatar}>
              {[...Array(5).keys()].map((index) => (
                <span
                  key={`track-tag-${index}`}
                  className="text-blue-500 font-medium">
                  {t(`lp:innovative_section:caption_${index + 1}`)}
                </span>
              ))}
            </Slider>
          </div>
        </div>
        <div className="max-w-[1216px] w-full border-l border-[#E3E6EC]">
          <div className="relative">
            <div
              style={{ height: 176 * 5 }}
              className={`absolute w-full border-l top-0 left-4 desktop:hidden`}
            />
            <div
              style={{ height: 176 * currentSliderIdx }}
              className={`absolute w-full border-l-2 top-0 left-4 border-blue-500 desktop:hidden`}
            />
            <div
              style={{ height: 129 * currentSliderIdx }}
              className={`absolute w-full border-l-2 top-0 border-blue-500 hidden desktop:block`}
            />
            {/* slick button */}
            <div className="z-10 absolute flex right-4 top-4 tablet:right-0 tablet:left-10 tablet:top-[36rem] gap-6 h-full">
              <div
                className="w-10 h-10 flex items-center justify-center cursor-pointer border border-[#DCDBE1] rounded-full shadow-[0_0_0_2px_#EBEFF6,0_0_0_4px_#F6F9FE,0_0_0_5px_#E3E6EC] bg-white hover:bg-gray-50"
                onClick={() => testimonialSliderRef?.current?.slickPrev()}>
                <IconWrapper name="ChevronLeft" color="#272A35" />
              </div>
              <div
                className="w-10 h-10 flex items-center justify-center cursor-pointer border border-[#DCDBE1] rounded-full shadow-[0_0_0_2px_#EBEFF6,0_0_0_4px_#F6F9FE,0_0_0_5px_#E3E6EC] bg-white hover:bg-gray-50"
                onClick={() => testimonialSliderRef?.current?.slickNext()}>
                <IconWrapper name="ChevronRight" color="#272A35" />
              </div>
            </div>
          </div>
          <Slider
            {...settingsSliderTestimonial}
            ref={testimonialSliderRef}
            className="tablet:h-[645px]"
            beforeChange={(_, nextSlide) => {
              setCurrentSliderIdx(nextSlide + 1)
              avatarSliderRef?.current?.slickGoTo(nextSlide)
            }}>
            {[...Array(5).keys()].map((index) => (
              <div
                key={`track-${index}`}
                className="h-[880px] selection:tablet:h-[645px]">
                <div className="w-full h-full flex pl-4 gap-[30px] tablet:h-auto tablet:gap-0 tablet:flex-row tablet:pl-[40px] tablet:pt-[40px]">
                  <div className="pt-8 tablet:pt-0 tablet:basis-2/5 flex flex-col">
                    <div className="pl-4 h-full justify-center flex flex-col gap-4 pr-4 tablet:pr-0 tablet:h-auto pb-6">
                      <img
                        src={'/img/feature/lp/reach_ic.png'}
                        alt="reach_ic"
                        className="w-10 h-10 tablet:w-12 tablet:h-12"
                      />
                      <span className="font-medium text-[23px] leading-[36px] tablet:text-[27px] tablet:leading-[42px]">
                        {t(
                          `lp:innovative_section:innovative_title_${index + 1}`
                        )}
                      </span>
                      <span>
                        {t(`lp:innovative_section:innovative_des_${index + 1}`)}
                      </span>
                      <div className="flex flex-col gap-2">
                        {[...Array(3).keys()].map((idx) => (
                          <div
                            key={`is-${idx}`}
                            className="flex flex-row gap-3 items-center text-gray-700">
                            <img
                              src={'/img/feature/lp/sparkle_blue.png'}
                              alt="reach_ic"
                              className="w-3 mb-[1px]"
                            />
                            <span>
                              {t(
                                `lp:innovative_section:innovative_key_${index + 1}:item_${idx + 1}`
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                      {hasRequestForm ? (
                        <Button
                          size="md"
                          className="w-fit"
                          type="quaternary"
                          onClick={gotoTop}>
                          <div className="flex items-center gap-2 px-1">
                            {t('lp:innovative_section:request_a_demo')}
                            <IconWrapper
                              size={16}
                              name="ArrowUpRight"
                              color="white"
                            />
                          </div>
                        </Button>
                      ) : (
                        <Link
                          href={LEAR_MORE_LINKS[index]}
                          locale={i18n?.language}
                          target="_blank">
                          <Button
                            size="md"
                            className="w-auto"
                            type="quaternary">
                            <div className="flex items-center gap-2 px-1">
                              {t('lp:innovative_section:learn_more')}
                              <IconWrapper
                                size={16}
                                name="ArrowUpRight"
                                color="white"
                              />
                            </div>
                          </Button>
                        </Link>
                      )}
                    </div>

                    <div className="desktop:hidden">
                      <Image
                        src={`/img/feature/lp/innovative${index + 1}_small.png`}
                        alt={`innovative${index + 1}_small`}
                        width={343}
                        height={314}
                        quality={100}
                      />
                    </div>
                  </div>
                  <div className="desktop:basis-3/5 px-[34px] hidden desktop:block">
                    <Image
                      src={`/img/feature/lp/innovative${index + 1}.png`}
                      alt={`innovative${index + 1}`}
                      width={650}
                      height={0}
                      className="h-auto"
                      quality={100}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default InnovativeSection
