import LPContainer from './LPContainer'
import { TypographyH2 } from '~/core/ui/Heading'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import DotBadge from './components/DotBadge'
import Image from 'next/image'

const TeamSection: FCC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`overflow-hidden mt-[100px] tablet:mt-[164px] ${className}`}>
      <LPContainer>
        <div className="flex-1 flex flex-col justify-center max-w-3xl mx-auto items-center text-center">
          <DotBadge
            label={t('lp:team_section:tag')}
            dotClassName="text-green-500"
          />

          <TypographyH2 className="mt-3 tablet:w-[400px] text-center text-4xl tablet:text-4.5xl font-normal">
            {t('lp:team_section:title')}
          </TypographyH2>
        </div>

        <div className="mt-8 tablet:mt-14 flex flex-col gap-5">
          <div className="flex gap-5 flex-col tablet:flex-row">
            <div className="max-w-[700px] bg-[#F6F9FE] border border-transparent rounded-xl pt-6">
              <div className="font-medium text-[19px] leading-7 text-center">
                {t('lp:team_section:manage_candidate_profiles')}
              </div>
              <div className="mt-1 text-[17px] leading-[26px] text-center px-3 tablet:px-8">
                {t('lp:team_section:manage_candidate_des')}
              </div>
              <Image
                src={'/img/feature/lp/manage_group_small.png'}
                alt="manage_group_small"
                width={367}
                height={314}
                className="mt-8 desktop:hidden"
                quality={100}
              />
              <img
                src={'/img/feature/lp/manage_group.png'}
                alt="manage_group"
                className="mt-8 h-auto w-full hidden desktop:block"
              />
            </div>
            <div className="bg-[#F6F9FE] border border-transparent rounded-xl pt-6">
              <div className="font-medium text-[19px] leading-7 text-center">
                {t('lp:team_section:integration')}
              </div>
              <div className="mt-1 text-[17px] leading-[26px] text-center px-3 tablet:px-8">
                {t('lp:team_section:integration_des')}
              </div>
              <div>
                <img
                  src={'/img/feature/lp/integrate.png'}
                  alt="integrate"
                  className="mt-8 w-full rounded-lg"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col tablet:flex-row gap-[21px]">
            <div className="max-w-[700px] bg-[#F6F9FE] border border-transparent rounded-xl pt-6">
              <div className="font-medium text-[19px] leading-7 text-center">
                {t('lp:team_section:user_permissions')}
              </div>
              <div className="mt-1 text-[17px] leading-[26px] text-center px-3 tablet:px-8">
                {t('lp:team_section:user_permissions_des')}
              </div>
              <Image
                src={'/img/feature/lp/user_permission_small.png'}
                alt="user_permission_small"
                width={343}
                height={306}
                className="mt-8 desktop:hidden"
                quality={100}
              />
              <img
                src={'/img/feature/lp/user_permission.png'}
                alt="user_permission"
                className=" mt-8 h-auto w-full hidden desktop:block"
              />
            </div>
            <div className="bg-[#F6F9FE] border border-transparent rounded-xl pt-6">
              <div className="font-medium text-[19px] leading-7 text-center">
                {t('lp:team_section:interview_scheduling')}
              </div>
              <div className="mt-1 text-[17px] leading-[26px] text-center px-3 tablet:px-8">
                {t('lp:team_section:interview_scheduling_des')}
              </div>
              <div>
                <Image
                  src={'/img/feature/lp/interview_small.png'}
                  alt="interview_small"
                  width={343}
                  height={328}
                  className="mt-8 desktop:hidden"
                  quality={100}
                />
                <img
                  src={'/img/feature/lp/interview.png'}
                  alt="interview"
                  className="mt-8 w-full rounded-lg px-9 hidden desktop:block"
                />
              </div>
            </div>
          </div>
        </div>
      </LPContainer>
    </div>
  )
}

export default TeamSection
